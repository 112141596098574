import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./Catories.module.scss";
import "../../../assets/Scsss/Global.scss";
// import './catoriesSummarypage.scss'
//assets
import "./Dashboard.scss";
import { useDetectClickOutside } from "react-detect-click-outside";
import Drawer from "react-modern-drawer";
import flatpickr from "flatpickr";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/material_orange.css";
import {
    CopyBtn,
    CustomCheckbox,
    EachCustomDropdown,
    EachCustomImageUpload,
    EachCustomImageUploadcustom,
    EachCustomSelectMultiple,
    EachCustomSelectMultiples,
    EachInputCustom,
    EachInputsepfic,
    EachCustomTextarea,
    EachCustomSelectMultiplelist,
    OwnEachCustomDropdown,
    EachInput,
} from "../../../components/Custom";

import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../../context/Globalcontext";

import axios from "axios";

// import Editingpage from '../EditingPage/EditingPage'
const Organization = () => {
    const {
        email,
        userLoginHandler,
        dropDowndownvalue,
        actionBtns,
        setActionBtns,
        globalSearchQuery,
        setGlobalSearchQuery,
    } = useContext(GlobalContext);
    const [checkBoxStage, setCheckBoxStage] = useState("");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const timpicker = [
        "12:00AM",
        "12:30AM",
        "1:00AM",
        "1:30AM",
        "2:00AM",
        "2:30AM",
        "3:00AM",
        "3:30AM",
        "4:00AM",
        "4:30AM",
        "5:00AM",
        "5:30AM",
        "6:00AM",
        "6:30AM",
        "7:00AM",
        "7:30AM",
        "8:00AM",
        "8:30AM",
        "9:00AM",
        "9:30AM",
        "10:00AM",
        "10:30AM",
        "11:00AM",
        "11:30AM",
        "12:00PM",
        "12:30PM",
        "1:00PM",
        "1:30PM",
        "2:00PM",
        "2:30PM",
        "3:00PM",
        "3:30PM",
        "4:00PM",
        "4:30PM",
        "5:00PM",
        "5:30PM",
        "6:00PM",
        "6:30PM",
        "7:00PM",
        "7:30PM",
        "8:00PM",
        "8:30PM",
        "9:00PM",
        "9:30PM",
        "10:00PM",
        "10:30PM",
        "11:00PM",
        "11:30PM",
    ];

    const [organization, setorganization] = useState({
        name: "",
        toggle: false,
    });
    const [land, setland] = useState({
        name: "",
        toggle: false,
    });

    const leadlist = [
        {
            name: "Online",
        },
        {
            name: "Phone",
        },
        {
            name: "Walk in",
        },
    ];
    const leadlistcuton = [
        {
            name: "Online",
        },
        {
            name: "Phone",
        },
        {
            name: "Walk in",
        },
        {
            name: "Affiliate",
        },
    ];

    const closeOrganization = () => {
        setorganization({
            name: organization?.name,
            toggle: false,
        });
    };

    const [bookingDrawer, setbookingDrawer] = useState(false);
    const [bookingItem, setbookingItem] = useState({});
    const [organizationlist, setorganizationlist] = useState([]);
    const [alldata, setalldata] = useState([]);
    const [location, setlocation] = useState([]);

    const [isSpecialist, setIsSpecialist] = useState(null);
    useEffect(() => {
        axios
            .get(
                `https://api.employment.group/api/getspecialistdetail?email=${email}`
            )
            .then(({ data }) => {
                if (data.success) {
                    setIsSpecialist(data?.data[0]?.specialistDetails[0]);
                } else {
                    setIsSpecialist(false);
                }
            });
    }, []);

    const clicktable = (e) => {
        setbookingItem(e);
        setbookingDrawer(true);
    };
    const [locationItem, setlocationItem] = useState({
        name: "",
        toggle: false,
    });
    const closelocation = () => {
        setlocationItem({
            name: locationItem?.name,
            toggle: false,
        });
    };

    const selectlocation = (item) => {
        setlocationItem({
            name: item,
            toggle: false,
        });
    };
    const [changevalue, setchangevalue] = useState(
        localStorage.getItem("listorginzationName")
    );
    const isLoggedIn = localStorage.getItem("listorginzationName");

    // useEffect(() => {
    //   function checkUserData() {
    //     localStorage.getItem('listorginzationName')

    //     tablefucntion()
    //     tablefucntionxxxx()
    //   }

    //   window.addEventListener('storage', checkUserData)

    //   return () => {
    //     window.removeEventListener('storage', checkUserData)
    //   }
    // }, [])

    useEffect(() => {
        setGlobalSearchQuery("");
        tablefucntion();
        tablefucntionxxxx();
    }, [dropDowndownvalue]);

    console.log("dropDowndownvalue", dropDowndownvalue);

    useEffect(() => {
        tablefucntion();
    }, [isSpecialist]);

    const tablefucntion = () => {
        if (isSpecialist === false) {
            axios
                .get(
                    `https://api.employment.group/api/getbooking?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
                )
                .then((res) => {
                    //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
                    setalldata(res?.data?.data);
                });
        } else if (isSpecialist !== null && isSpecialist !== false) {
            axios
                .get(
                    `https://api.employment.group/api/getbooking?specialistid=${isSpecialist?.specialistid}`
                )
                .then((res) => {
                    //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
                    setalldata(res?.data?.data);
                });
        }
    };

    const tablefucntionxxxx = () => {
        axios
            .get(
                `https://api.employment.group/api/getlocation?teamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
                setlocation(res?.data?.data);
            });
    };

    const [lead, setlead] = useState({
        name: "",
        toggle: false,    });
    const closelead = () => {
        setlead({
            name: lead?.name,
            toggle: false,
        });
    };

    const selectOrganzitionfunction = (item) => {
        setorganization({
            name: item,
            toggle: false,
        });
    };
    const selectlead = (item) => {
        setlead({
            name: item,
            toggle: false,
        });
    };
    // if (organization?.toggle) {

    // }

    console.log("czxczxc", lead);

    const datepickerRef = useRef(null);
    const datepickerRef1 = useRef(null);
    const datepickerRef2 = useRef(null);
    const [dateshow, setdateshow] = useState({
        name: "",
        toggle: false,
    });

    useEffect(() => {
        if (datepickerRef?.current) {
            const options = {
                dateFormat: "d-m-Y",
                // Other options here
                onChange: function (selectedDates, dateStr, instance) {
                    console.log("Selected start date:", dateStr, datepickerRef?.current);
                    setdateshow({
                        name: dateStr,
                    });
                },
            };

            flatpickr(datepickerRef.current, options);
        }
    }, []);

    const [timeshow, settimeshow] = useState({
        name: "",
        toggle: false,
    });

    const selettimeshow = (e) => {
        settimeshow({
            name: e,
            toggle: false,
        });
    };

    const [groupName, setgroupName] = useState("");
    const [Email, setEmail] = useState("");
    const [phone, setphone] = useState("");
    const [semail, setsemail] = useState("");

    const [changestatus, setchangestatus] = useState("");

    console.log("bookingDrawer", bookingDrawer);

    function toCardinal(num) {
        var ones = num % 10;
        var tens = num % 100;

        if (tens < 11 || tens > 13) {
            switch (ones) {
                case 1:
                    return num + "st";
                case 2:
                    return num + "nd";
                case 3:
                    return num + "rd";
            }
        }

        return num + "th";
    }
    var monthNames = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ];

    const [step, setstep] = useState("main");

    //
    const [leadsoruce, setleadsoruce] = useState({
        name: "",
        toggle: false,
    });
    const [refid, setrefid] = useState("");
    const [showrefbox, setshowrefbox] = useState(false);
    const [refmail, setrefmail] = useState({});
    const [mailchek, setmailchek] = useState("");

    const ref = useDetectClickOutside({
        onTriggered: closeOrganization,
        allowAnyKey: true,
    });
    const ref1 = useDetectClickOutside({
        onTriggered: closelead,
        allowAnyKey: true,
    });
    const [answerref, setanswerref] = useState(false);
    const cmailcheck = () => {
        if (mailchek == "") {
        } else {
            axios
                .get(
                    `https://comms.globalxchange.io/user/profile/data/get?email=${mailchek}`
                )
                .then((res) => {
                    if (res?.data.count > 0) {
                        setrefmail(res?.data?.usersData?.[0]);
                        setrefid(
                            res?.data?.usersData?.[0]?.hardCoded?.[0]?.data?.affiliate_id
                        );
                        setshowrefbox(false);
                        setanswerref(true);
                    } else {
                        setrefmail({});
                        setrefid("");
                        toast("Error");
                        setshowrefbox(true);
                    }
                });
        }
    };

    const selectleadsoruce = (e) => {
        if (e == "Affiliate") {
            setshowrefbox(true);
            setanswerref(false);
        } else {
            setshowrefbox(false);
            setanswerref(false);
            setrefid("6535e2f65958ffca65b865ef21fde26f0b8406ca");
        }
        setleadsoruce({
            name: e,
            toggle: false,
        });
    };
    const person = [
        {
            name: "Male",
        },
        {
            name: "Female",
        },
        {
            name: "Other",
        },
    ];
    const [gender, setgender] = useState({
        name: "",
        toggle: false,
    });
    const selectgender = (e) => {
        setgender({
            name: e,
            toggle: false,
        });
    };
    const [brithday, setbrithday] = useState("");

    useEffect(() => {
        if (datepickerRef1?.current) {
            const options = {
                dateFormat: "m/d/Y",
                // Other options here
                onChange: function (selectedDates, dateStr, instance) {
                    console.log("Selected start date:", dateStr, datepickerRef1?.current);
                    setbrithday(dateStr);
                },
            };

            flatpickr(datepickerRef1.current, options);
        }
    }, [datepickerRef1?.current]);

    const [clientName, setclientName] = useState("");
    const [clientphone, setclientphone] = useState("");
    const [emailclient, setemailclient] = useState("");
    const [userNameclient, setuserNameclient] = useState("");
    const [country, setcountry] = useState({
        name: "",
        toggle: false,
    });
    const [state, setstate] = useState("");
    const [city, setcity] = useState("");
    const [address, setaddress] = useState("");
    const [pincode, setpincode] = useState("");

    useEffect(() => {
        hetconstultant();
        clientstorefucn();
        gettoday();
    }, [dropDowndownvalue]);

    useEffect(() => {
        Therpygetloc()
        selecttherpyfunction()
        Therpygetspe()
    }, []);

    const [constitid, setconstitid] = useState("");

    const [servicedropClinet, setservicedropClinet] = useState([]);
    const [SelectTherpy, setSelectTherpy] = useState([]);
    const [servicestore, setservicestore] = useState({
        name: "",
        toggle: false,
    });

    const [loctionService, setloctionService] = useState({
        name: "",
        toggle: false,
    });

    const [locationdrop, setlocationdrop] = useState([]);
    const [Therpylocationdrop, setTherpylocationdrop] = useState([]);
    const [locationid, setlocationid] = useState("");

    const [specService, setspecService] = useState({
        name: "",
        toggle: false,
    });
    const [specDrown, setspecDrown] = useState([]);
    const [TherpyspecDrown, setTherpyspecDrown] = useState([]);
    const [specid, setspecid] = useState("");
    const selecttherpyfunction = () => {
        axios
            .get(
                `https://api.employment.group/api/getconsultationservice?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                if (res.data.success) {
                    setservicedropClinet(res?.data?.data);
                    // setconstitid(res?.data?.data?.[0]?.consultationserviceid)
                    // getservice(res?.data?.data?.[0]?.consultationserviceid)
                } else {
                    setservicedropClinet([]);
                }
                //
            });
    };


    const hetconstultant = () => {
        axios
            .get(
                `https://api.employment.group/api/gettheraphy?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                if (res.data.success) {
                    setSelectTherpy(res?.data?.data);
                    // setconstitid(res?.data?.data?.[0]?.consultationserviceid)
                    // getservice(res?.data?.data?.[0]?.consultationserviceid)
                } else {
                    setSelectTherpy([]);
                }
                //
            });
    };
    const [therpyid, settherpiId] = useState("")

    const therapyservicefunction = (e) => {
        console.log("popo", e);

        settherpiId(e?.theraphyId)
        // setconstitid(e?.consultationserviceid);
        setservicestore({
            name: e?.name,
            toggle: false,
        });

        //    getloc(e?.consultationserviceid);

        //setlocationid("");

        //setspecid("");
        setloctionService({
            name: "",
            toggle: false,
        });

        setspecService({
            name: "",
            toggle: false,
        });
    };



    const seletservice = (e) => {
        console.log("popo", e);
        setconstitid(e?.consultationserviceid);
        setservicestore({
            name: e?.name,
            toggle: false,
        });

        getloc(e?.consultationserviceid);

        setlocationid("");

        setspecid("");
        setloctionService({
            name: "",
            toggle: false,
        });

        setspecService({
            name: "",
            toggle: false,
        });
    };

    const callcler = (e) => {
        setappointdate("");
        setconstitid("");
        setservicestore({
            name: "",
            toggle: false,
        });

        settimeshow({
            name: "",
            toggle: false,
        });

        setlocationid("");

        setspecid("");
        setloctionService({
            name: "",
            toggle: false,
        });

        setspecService({
            name: "",
            toggle: false,
        });
    };

    const seletlocationfucn = (e) => {
        setlocationid(e?.locationId);
        setloctionService({
            name: e?.locationDetails?.[0]?.name,
            toggle: false,
        });
        getspe(e?.locationId);
    };

    const Therpyexsitingseletlocationfucn = (e) => {
        setlocationid(e?._id);
        setloctionService({
            name: e?.name,
            toggle: false,
        });
        // getspe(e?.locationId);
    };

    const Therpyseletlocationfucn = (e) => {

        setlocationid(e?._id);
        setloctionService({
            name: e?.name,
            toggle: false,
        });
        // getspe(e?.locationId);
    };


    const seletspecfuncion = (e) => {
        setspecid(e?.specialistid);
        setspecService({
            name: e?.TeamDetails?.[0]?.PlayerDetails?.[0]?.name,
            toggle: false,
        });
    };

    const getspe = (e) => {
        console.log("c72348243", e, constitid);
        axios
            .get(
                `https://api.employment.group/api/getspecialist?locationid=${e}&serviceids=${constitid}`
            )
            .then((res) => {
                if (res.data.success) {
                    setspecDrown(res?.data?.data);
                } else {
                    setspecDrown([]);
                }
                //
            });
    };

    const Therpygetspe = () => {
        // console.log("c72348243", e, constitid);
        axios
            .get(
                `https://api.employment.group/api/getspecialist?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                if (res.data.success) {
                    setTherpyspecDrown(res?.data?.data);
                } else {
                    setTherpyspecDrown([]);
                }
                //
            });
    };


    const getloc = (e) => {
        axios
            .get(
                `https://api.employment.group/api/getconsultationservice?consultationserviceid=${e}`
            )
            .then((res) => {
                if (res.data.success) {
                    setlocationdrop(res?.data?.data);
                } else {
                    setlocationdrop([]);
                }
                //
            });
    };


    const Therpygetloc = (e) => {
        axios
            .get(
                `https://api.employment.group/api/getlocation?teamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                if (res.data.success) {
                    setTherpylocationdrop(res?.data?.data);
                } else {
                    setTherpylocationdrop([]);
                }
                //
            });
    };


    const [appointdate, setappointdate] = useState("");
    useEffect(() => {
        if (datepickerRef2?.current) {
            const options = {
                dateFormat: "m/d/Y",
                // Other options here
                onChange: function (selectedDates, dateStr, instance) {
                    console.log("Selected start date:", dateStr, datepickerRef2?.current);
                    setappointdate(dateStr);
                },
            };

            flatpickr(datepickerRef2.current, options);
        }
    }, [datepickerRef2?.current]);

    const [coundata, setcoundata] = useState([]);

    const countryselete = (e) => {
        setcountry({
            name: e?.name,
            toggle: false,
        });
    };

    useEffect(() => {
        countryfunct();
    }, []);









    const countryfunct = () => {
        axios
            .get(`https://comms.globalxchange.io/coin/vault/countries/data/get`)
            .then((res) => {
                if (res.data.status) {
                    setcoundata(res?.data?.countries);
                } else {
                    setcoundata([]);
                }
                //
            });
    };
    const [loading, setloading] = useState(false);
    const submitnewdata = () => {
        let pop = brithday?.split("/");
        setloading(true);
        if (specService?.name != "") {
            axios
                .post("https://api.employment.group/api/createbooking", {
                    teamusername:
                        JSON.parse(localStorage?.getItem("orginzationdetail"))
                            ?.TeamUsername || "",
                    phone: clientphone,
                    leadSource: leadsoruce?.name,
                    specialistid: specid,
                    dateOfBooking: appointdate,
                    timeOfBooking: timeshow?.name,
                    username: userNameclient,
                    name: clientName,
                    email: emailclient,
                    YearOfBirth: pop[2],
                    birthday: brithday,

                    Country: country?.name,
                    Address: address,
                    pincode: pincode,
                    state: state,
                    city: city,
                    Gender: gender?.name,
                    ref_affiliate: refid,
                    serviceId: constitid,
                    locationId: locationid,
                })
                .then(({ data }) => {
                    if (data.status) {
                        tablefucntion();
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                        setloading(false);
                        toast("SuccessFully Submited ");
                        setstep("main");
                        setlocationid("");
                        setspecService({
                            name: "",
                            toggle: false,
                        });
                        setclientphone("");
                        setleadsoruce({
                            name: "",
                            toggle: false,
                        });
                        setloctionService({
                            name: "",
                            toggle: false,
                        });
                        setspecid("");
                        setappointdate("");
                        settimeshow({
                            name: "",
                            toggle: false,
                        });
                        setclientName("");
                        setemailclient("");
                        setbrithday("");
                        setcountry({
                            name: "",
                            toggle: false,
                        });
                        setaddress("");
                        setpincode("");
                        setstate("");
                        setcity("");
                        setgender({
                            name: "",
                            toggle: false,
                        });
                        setservicestore({
                            name: "",
                            toggle: false,
                        });
                        setrefid("");
                        setconstitid("");
                        setlocationid("");
                    } else {
                        toast(data?.message);
                        setloading(false);
                    }
                });
        } else {
            toast("Select Specialist");
            setloading(false);
        }
    };

    const [exsitingclient, setexsitingclient] = useState({
        name: "",
        toggle: false,
    });

    const [texsitingclient, settexsitingclient] = useState({
        name: "",
        toggle: false,
    });
    const [clientid, setclientid] = useState("");
    const [therpyclientid, settherpyclientid] = useState("");
    const [clientstore, setclientstore] = useState([]);
    const seletclientfunct = (e) => {
        setclientid(e?.UserDetails?.[0]?.UserDetails?.[0]?.userid);
        setexsitingclient({
            name: e?.UserDetails?.[0]?.UserDetails?.[0]?.name,
            toggle: false,
        });
    };


    const theropyseletclientfunct = (e) => {
        settherpyclientid(e?.theraphyId);

        settexsitingclient({
            name: e?.name,
            toggle: false,
        });
    };


    const clientstorefucn = () => {
        axios
            .get(
                `https://api.employment.group/api/getallclientsforteam/${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                if (res.data.success) {
                    setclientstore(res?.data?.data);
                } else {
                    setcoundata([]);
                }
                //
            });
    };

    const [lenghttoday, setlenghttoday] = useState("");
    const gettoday = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + "/" + dd + "/" + yyyy;

        if (isSpecialist === false) {
            axios
                .get(
                    `https://api.employment.group/api/getbooking?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}&&startDate=${today}&&endDate=${today}`
                )
                .then((res) => {
                    if (res.data.success) {
                        setlenghttoday(res?.data?.Total_bookings);
                    } else {
                        setlenghttoday(0);
                    }
                });
        } else if (isSpecialist !== null && isSpecialist !== false) {
            axios
                .get(
                    `https://api.employment.group/api/getbooking?specialistid=${isSpecialist?.specialistid}&&startDate=${today}&&endDate=${today}`
                )
                .then((res) => {
                    if (res.data.success) {
                        setlenghttoday(res?.data?.Total_bookings);
                    } else {
                        setlenghttoday(0);
                    }
                });
        }
    };



    const empydata = () => {
        settherpiId("")
        setstep("main");
        setlocationid("");
        setspecService({
            name: "",
            toggle: false,
        });
        setclientphone("");
        setleadsoruce({
            name: "",
            toggle: false,
        });
        setloctionService({
            name: "",
            toggle: false,
        });
        setspecid("");
        setappointdate("");
        settimeshow({
            name: "",
            toggle: false,
        });
        setclientName("");
        setemailclient("");
        setbrithday("");
        setcountry({
            name: "",
            toggle: false,
        });
        setaddress("");
        setpincode("");
        setstate("");
        setcity("");
        setgender({
            name: "",
            toggle: false,
        });
        setservicestore({
            name: "",
            toggle: false,
        });
        setrefid("");
        setconstitid("");
        setlocationid("");
    }

    const empytexitingdat = () => {
        setgroupName("");
        setsemail("");
        setphone("");
        setEmail("");
        setorganization({
            name: "",
            toggle: false,
        });
        setlocationItem({
            name: "",
            toggle: false,
        });
        setlead({
            name: "",
            toggle: false,
        });
        setdateshow({
            name: "",
            toggle: false,
        });
        settimeshow({
            name: "",
            toggle: false,
        });

        setappointdate("");
        setconstitid("");
        setservicestore({
            name: "",
            toggle: false,
        });

        settimeshow({
            name: "",
            toggle: false,
        });

        setlocationid("");

        setspecid("");

        setservicestore({
            name: "",
            toggle: false,
        });
        setloctionService({
            name: "",
            toggle: false,
        });
        setexsitingclient({
            name: "",
            toggle: false,
        });

        setspecService({
            name: "",
            toggle: false,
        });
        settherpyclientid("")
    }

    const therpysubmitfuction = () => {
        setloading(true);
        axios
            .post(
                "https://api.employment.group/api/createbooking",

                {
                    teamusername:
                        JSON.parse(localStorage?.getItem("orginzationdetail"))
                            ?.TeamUsername || "",
                    specialistid: specid,
                    theraphyId: therpyclientid,
                    dateOfBooking: appointdate,
                    timeOfBooking: timeshow?.name,
                    locationId: locationid,
                    clientId: clientid,

                }
            )
            .then(({ data }) => {
                if (data.status) {
                    //tablefucntion();
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                    setgroupName("");
                    setsemail("");
                    setphone("");
                    setEmail("");
                    setorganization({
                        name: "",
                        toggle: false,
                    });
                    setlocationItem({
                        name: "",
                        toggle: false,
                    });
                    setlead({
                        name: "",
                        toggle: false,
                    });
                    setdateshow({
                        name: "",
                        toggle: false,
                    });
                    settimeshow({
                        name: "",
                        toggle: false,
                    });

                    setappointdate("");
                    setconstitid("");
                    setservicestore({
                        name: "",
                        toggle: false,
                    });

                    settimeshow({
                        name: "",
                        toggle: false,
                    });

                    setlocationid("");

                    setspecid("");

                    setservicestore({
                        name: "",
                        toggle: false,
                    });
                    setloctionService({
                        name: "",
                        toggle: false,
                    });
                    setexsitingclient({
                        name: "",
                        toggle: false,
                    });

                    setspecService({
                        name: "",
                        toggle: false,
                    });
                    toast("SuccessFully Submited ");
                    setstep("main");
                } else {
                    setloading(false);
                    toast(data?.message);
                }
            });


    }
    const submitfuction = () => {
        axios
            .post(
                "https://api.employment.group/api/createbooking",

                {
                    teamusername:
                        JSON.parse(localStorage?.getItem("orginzationdetail"))
                            ?.TeamUsername || "",
                    specialistid: specid,
                    dateOfBooking: appointdate,
                    timeOfBooking: timeshow?.name,
                    locationId: locationid,
                    clientId: clientid,
                    serviceId: constitid,
                }
            )
            .then(({ data }) => {
                if (data.status) {
                    //tablefucntion();
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 2000);
                    setgroupName("");
                    setsemail("");
                    setphone("");
                    setEmail("");
                    setorganization({
                        name: "",
                        toggle: false,
                    });
                    setlocationItem({
                        name: "",
                        toggle: false,
                    });
                    setlead({
                        name: "",
                        toggle: false,
                    });
                    setdateshow({
                        name: "",
                        toggle: false,
                    });
                    settimeshow({
                        name: "",
                        toggle: false,
                    });

                    setappointdate("");
                    setconstitid("");
                    setservicestore({
                        name: "",
                        toggle: false,
                    });

                    settimeshow({
                        name: "",
                        toggle: false,
                    });

                    setlocationid("");

                    setspecid("");

                    setservicestore({
                        name: "",
                        toggle: false,
                    });
                    setloctionService({
                        name: "",
                        toggle: false,
                    });
                    setexsitingclient({
                        name: "",
                        toggle: false,
                    });

                    setspecService({
                        name: "",
                        toggle: false,
                    });
                    toast("SuccessFully Submited ");
                    setstep("main");
                } else {
                    toast(data?.message);
                }
            });
    };
    const submitherpy = () => {
        let pop = brithday?.split("/");
        setloading(true);
        if (specService?.name != "") {
            axios
                .post("https://api.employment.group/api/createbooking", {
                    teamusername:
                        JSON.parse(localStorage?.getItem("orginzationdetail"))
                            ?.TeamUsername || "",
                    phone: clientphone,
                    leadSource: leadsoruce?.name,
                    specialistid: specid,
                    dateOfBooking: appointdate,
                    timeOfBooking: timeshow?.name,
                    username: userNameclient,
                    name: clientName,
                    email: emailclient,
                    YearOfBirth: pop?.[2],
                    birthday: brithday,

                    Country: country?.name,
                    Address: address,
                    pincode: pincode,
                    state: state,
                    city: city,
                    Gender: gender?.name,
                    ref_affiliate: refid,
                    theraphyId: therpyid,
                    locationId: locationid,
                })
                .then(({ data }) => {
                    if (data.status) {
                        tablefucntion();
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                        setloading(false);
                        toast("SuccessFully Submited ");
                        settherpiId("")
                        setstep("main");
                        setlocationid("");
                        setspecService({
                            name: "",
                            toggle: false,
                        });
                        setclientphone("");
                        setleadsoruce({
                            name: "",
                            toggle: false,
                        });
                        setloctionService({
                            name: "",
                            toggle: false,
                        });
                        setspecid("");
                        setappointdate("");
                        settimeshow({
                            name: "",
                            toggle: false,
                        });
                        setclientName("");
                        setemailclient("");
                        setbrithday("");
                        setcountry({
                            name: "",
                            toggle: false,
                        });
                        setaddress("");
                        setpincode("");
                        setstate("");
                        setcity("");
                        setgender({
                            name: "",
                            toggle: false,
                        });
                        setservicestore({
                            name: "",
                            toggle: false,
                        });
                        setrefid("");
                        setconstitid("");
                        setlocationid("");
                    } else {
                        toast(data?.message);
                        setloading(false);
                    }
                });
        } else {
            toast("Select Specialist");
            setloading(false);
        }
    };


    const createnewfunction = () => {
        switch (step) {

            case "therapyNew":
                return (
                    <>
                        <div className="booking-dashboard">
                            <div className="bottomleftside">
                                <div className="scroll-bottom-div">
                                    <div
                                        className="titleseleted"
                                        style={{
                                            fontWeight: "700",
                                            marginTop: "63px",
                                        }}
                                    >
                                        {" "}
                                        Select the lead source
                                    </div>
                                    <div
                                        className="dropdownParentSection"
                                        ref={ref1}
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            className="dropdown-planb"
                                            onClick={() =>
                                                setleadsoruce({
                                                    toggle: !leadsoruce?.toggle,
                                                    name: leadsoruce?.name,
                                                })
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {leadsoruce.name == ""
                                                    ? "Click To Select"
                                                    : leadsoruce.name}
                                            </div>
                                            {leadsoruce.toggle ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            )}
                                        </div>
                                        {leadsoruce.toggle && (
                                            <div className="dropdwonlist">
                                                {leadlistcuton?.map((item) => {
                                                    return (
                                                        <div
                                                            className="listitemName"
                                                            onClick={() => selectleadsoruce(item?.name)}
                                                        >
                                                            {item?.name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>

                                    {showrefbox && (
                                        <>
                                            <div
                                                className="titleseleted"
                                                style={{
                                                    fontWeight: "700",
                                                }}
                                            >
                                                {" "}
                                                Enter affiliate
                                            </div>
                                            <div className="dropdownParentSectioncutom">
                                                <input
                                                    value={mailchek}
                                                    placeholder="Email.."
                                                    className="input"
                                                    onChange={(e) => setmailchek(e.target.value)}
                                                />
                                                <label onClick={cmailcheck}>Check</label>
                                            </div>
                                        </>
                                    )}

                                    {answerref && (
                                        <>
                                            <div
                                                className="titleseleted"
                                                style={{
                                                    fontWeight: "700",
                                                }}
                                            >
                                                {" "}
                                                Enter affiliate
                                            </div>

                                            <div
                                                className="dropdownParentSection"
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                <div
                                                    className="dropdown-planb"
                                                    style={{
                                                        height: "60px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                width: "41px",
                                                                height: "41px",
                                                                borderRadius: "10rem",
                                                                marginRight: "10px",
                                                            }}
                                                            src={refmail?.dynamic?.[0]?.data?.profile_img}
                                                        />
                                                        <div
                                                            style={{
                                                                width: "15rem",

                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                            }}
                                                        >
                                                            {refmail?.hardCoded?.[0]?.data?.username}

                                                            <div>
                                                                {refmail?.hardCoded?.[0]?.data?.affiliate_id}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <svg
                                                        onClick={() => {
                                                            setshowrefbox(true);
                                                            setanswerref(false);
                                                        }}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="19"
                                                        height="22"
                                                        viewBox="0 0 19 22"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M17.4289 6.9958C17.2278 6.68143 16.8128 6.61855 16.511 6.83233L14.4738 8.36652C14.2223 8.5551 14.1469 8.90724 14.3103 9.18385C14.8259 10.0641 15.09 11.0072 15.09 11.9881C15.09 15.1067 12.5498 17.6469 9.43124 17.6469C6.31269 17.6469 3.7725 15.1067 3.7725 11.9881C3.7725 9.08329 5.97312 6.68143 8.80249 6.36705V8.21562C8.80249 8.59287 9.10429 8.83179 9.43124 8.84437C9.55695 8.84437 9.69536 8.8066 9.80849 8.71858L14.8385 4.94616C15.1781 4.69466 15.1781 4.19162 14.8385 3.94012L9.80849 0.125708C9.69536 0.0377669 9.55699 0 9.43124 0C9.10429 0 8.80249 0.264117 8.80249 0.62875V2.58211C3.89821 2.89644 0 6.9958 0 11.9881C0 17.1942 4.2252 21.4613 9.43124 21.4613C14.6373 21.4613 18.8625 17.1942 18.8625 11.9881C18.8625 10.2276 18.3594 8.5048 17.4289 6.9958Z"
                                                            fill="#C57E50"
                                                        />
                                                    </svg>
                                                </div>
                                                {leadsoruce.toggle && (
                                                    <div className="dropdwonlist">
                                                        {leadlistcuton?.map((item) => {
                                                            return (
                                                                <div
                                                                    className="listitemName"
                                                                    onClick={() => selectleadsoruce(item?.name)}
                                                                >
                                                                    {item?.name}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}

                                    {/*  */}
                                    <div className="linestring"></div>
                                    <div
                                        className="titleseleted"
                                        style={{
                                            fontWeight: "700",
                                        }}
                                    >
                                        {" "}
                                        Client details
                                    </div>

                                    <div className="dropdownParentSectioncutom">
                                        <input
                                            value={clientName}
                                            placeholder="Client name..."
                                            className="input"
                                            onChange={(e) => setclientName(e.target.value)}
                                        />
                                    </div>

                                    <div
                                        className="dropdownParentSectioncutom"
                                        style={{
                                            width: "100%",

                                            marginTop: "0.8rem",
                                        }}
                                    >
                                        <input
                                            value={clientphone}
                                            placeholder="Phone number..."
                                            className="input"
                                            onChange={(e) => setclientphone(e.target.value)}
                                        />
                                    </div>

                                    <div
                                        className="dropdownParentSectioncutom"
                                        style={{
                                            width: "100%",

                                            marginTop: "0.8rem",
                                        }}
                                    >
                                        <input
                                            value={emailclient}
                                            placeholder="Email..."
                                            className="input"
                                            onChange={(e) => setemailclient(e.target.value)}
                                        />
                                    </div>

                                    <div
                                        className="dropdownParentSection"
                                        ref={ref1}
                                        style={{
                                            width: "100%",

                                            marginTop: "0.8rem",
                                        }}
                                    >
                                        <div
                                            className="dropdown-planb"
                                            onClick={() =>
                                                setgender({
                                                    toggle: !gender?.toggle,
                                                    name: gender?.name,
                                                })
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {gender.name == "" ? "Select gender" : gender.name}
                                            </div>
                                            {gender.toggle ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            )}
                                        </div>
                                        {gender.toggle && (
                                            <div className="dropdwonlist">
                                                {person?.map((item) => {
                                                    return (
                                                        <div
                                                            className="listitemName"
                                                            onClick={() => selectgender(item?.name)}
                                                        >
                                                            {item?.name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>

                                    <div
                                        className="dropdownParentSection"
                                        style={{
                                            marginTop: "0.8rem",
                                            width: "100%",
                                        }}
                                    >
                                        <div className="dropdown-planb">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <input
                                                    type="text"
                                                    id="datepicker1"
                                                    ref={datepickerRef1}
                                                    className="dateinput"
                                                    placeholder="Select birthday"
                                                    value={brithday}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="dropdownParentSectioncutom"
                                        style={{
                                            width: "100%",

                                            marginTop: "0.8rem",
                                        }}
                                    >
                                        <input
                                            value={userNameclient}
                                            placeholder="Username..."
                                            className="input"
                                            onChange={(e) => setuserNameclient(e.target.value)}
                                        />
                                    </div>
                                    <div className="linestring"></div>
                                    <div
                                        className="titleseleted"
                                        style={{
                                            fontWeight: "700",
                                        }}
                                    >
                                        {" "}
                                        Client address
                                    </div>

                                    <div
                                        className="dropdownParentSection"
                                        ref={ref1}
                                        style={{
                                            width: "100%",

                                            // marginTop: "0.8rem"
                                        }}
                                    >
                                        <div
                                            className="dropdown-planb"
                                            onClick={() =>
                                                setcountry({
                                                    toggle: !country?.toggle,
                                                    name: country?.name,
                                                })
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {country.name == "" ? "Select country" : country.name}
                                            </div>
                                            {country.toggle ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            )}
                                        </div>
                                        {country.toggle && (
                                            <div
                                                className="dropdwonlist"
                                                style={{
                                                    height: "12rem",
                                                    overflow: "scroll",
                                                }}
                                            >
                                                {coundata?.map((item) => {
                                                    return (
                                                        <div
                                                            className="listitemName"
                                                            onClick={() => countryselete(item)}
                                                        >
                                                            {item?.name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>

                                    <div
                                        className="dropdownParentSectioncutom"
                                        style={{
                                            width: "100%",

                                            marginTop: "0.8rem",
                                        }}
                                    >
                                        <input
                                            value={state}
                                            placeholder="State..."
                                            className="input"
                                            onChange={(e) => setstate(e.target.value)}
                                        />
                                    </div>

                                    <div
                                        className="dropdownParentSectioncutom"
                                        style={{
                                            width: "100%",

                                            marginTop: "0.8rem",
                                        }}
                                    >
                                        <input
                                            value={city}
                                            placeholder="City..."
                                            className="input"
                                            onChange={(e) => setcity(e.target.value)}
                                        />
                                    </div>

                                    <div
                                        className="dropdownParentSectioncutom"
                                        style={{
                                            width: "100%",

                                            marginTop: "0.8rem",
                                        }}
                                    >
                                        <input
                                            value={address}
                                            placeholder="Address..."
                                            className="input"
                                            onChange={(e) => setaddress(e.target.value)}
                                        />
                                    </div>
                                    <div
                                        className="dropdownParentSectioncutom"
                                        style={{
                                            width: "100%",

                                            marginTop: "0.8rem",
                                        }}
                                    >
                                        <input
                                            value={pincode}
                                            placeholder="Pincode..."
                                            className="input"
                                            onChange={(e) => setpincode(e.target.value)}
                                        />
                                    </div>

                                    <div className="linestring"></div>
                                    <div
                                        className="titleseleted"
                                        style={{
                                            fontWeight: "700",
                                        }}
                                    >
                                        {" "}
                                        Appointment details
                                    </div>

                                    <div className="titleseleted">Select Therapy</div>
                                    <div
                                        className="dropdownParentSection"
                                        ref={ref1}
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            className="dropdown-planb"
                                            onClick={() =>
                                                setservicestore({
                                                    toggle: !servicestore?.toggle,
                                                    name: servicestore?.name,
                                                })
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {servicestore.name == ""
                                                    ? "Click To Select"
                                                    : servicestore.name}
                                            </div>
                                            {servicestore.toggle ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            )}
                                        </div>

                                        {servicestore.toggle && (
                                            <div className="dropdwonlist">
                                                {SelectTherpy?.map((item) => {
                                                    return (
                                                        <div
                                                            className="listitemName"
                                                            onClick={() => therapyservicefunction(item)}
                                                        >
                                                            {item?.name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>

                                    <div className="titleseleted">Select location</div>
                                    <div
                                        className="dropdownParentSection"
                                        ref={ref1}
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            className="dropdown-planb"
                                            onClick={() =>
                                                setloctionService({
                                                    toggle: !loctionService?.toggle,
                                                    name: loctionService?.name,
                                                })
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {loctionService.name == ""
                                                    ? "Click To Select"
                                                    : loctionService.name}
                                            </div>
                                            {loctionService.toggle ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            )}
                                        </div>

                                        {loctionService.toggle && (
                                            <div className="dropdwonlist">
                                                {Therpylocationdrop?.map((item) => {
                                                    return (
                                                        <div
                                                            className="listitemName"
                                                            onClick={() => Therpyseletlocationfucn(item)}
                                                        >
                                                            {item?.name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>

                                    <div className="titleseleted">Select specialist</div>
                                    <div
                                        className="dropdownParentSection"
                                        ref={ref1}
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            className="dropdown-planb"
                                            onClick={() =>
                                                setspecService({
                                                    toggle: !specService?.toggle,
                                                    name: specService?.name,
                                                })
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {specService.name == ""
                                                    ? "Click To Select"
                                                    : specService.name}
                                            </div>
                                            {specService.toggle ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            )}
                                        </div>

                                        {specService.toggle && (
                                            <div className="dropdwonlist">
                                                {TherpyspecDrown?.map((item) => {
                                                    return (
                                                        <div
                                                            className="listitemName"
                                                            onClick={() => seletspecfuncion(item)}
                                                        >
                                                            {item?.TeamDetails?.[0]?.PlayerDetails?.[0]?.name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>

                                    {/*  */}

                                    <div className="titleseleted"> Select the date</div>
                                    <div
                                        className="dropdownParentSection"
                                        // ref={ref1}
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            className="dropdown-planb"

                                        // onClick={() => setlead({
                                        //   toggle: !lead?.toggle,
                                        //   name: lead?.name
                                        // })}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <input
                                                    type="text"
                                                    id="datepicker"
                                                    ref={datepickerRef2}
                                                    className="dateinput"
                                                    placeholder="Click To Select"
                                                    value={appointdate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="titleseleted"> Select the time</div>
                                    <div
                                        className="dropdownParentSection"
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            className="dropdown-planb"
                                            onClick={() =>
                                                settimeshow({
                                                    toggle: !timeshow?.toggle,
                                                    name: timeshow?.name,
                                                })
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {timeshow.name == ""
                                                    ? "Click To Select"
                                                    : timeshow.name}
                                            </div>
                                            {timeshow.toggle ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            )}
                                        </div>

                                        {timeshow.toggle && (
                                            <div
                                                className="dropdwonlist"
                                                style={{
                                                    height: "12rem",
                                                    overflow: " scroll",
                                                }}
                                            >
                                                {timpicker?.map((item) => {
                                                    return (
                                                        <div
                                                            className="listitemName"
                                                            onClick={() => selettimeshow(item)}
                                                        >
                                                            {item}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>

                                    <label
                                        className="submit"
                                        style={{
                                            marginTop: "2.5rem",
                                        }}
                                        onClick={submitherpy}
                                    >
                                        {loading ? "Loading..." : "Submit"}
                                    </label>

                                    <label
                                        className="submit"
                                        style={{
                                            background: "linear-gradient(89.14deg, #F04E8C 0.79%, #F8BA4E 143.99%)",
                                        }}
                                        onClick={() => setarr("main")}
                                    >
                                        Go Back
                                    </label>
                                </div>
                            </div>
                        </div>
                    </>
                );
                break;






            case "newclinet":
                return (
                    <>
                        <div className="booking-dashboard">
                            <div className="bottomleftside">
                                <div className="scroll-bottom-div">
                                    <div
                                        className="titleseleted"
                                        style={{
                                            fontWeight: "700",
                                            marginTop: "63px",
                                        }}
                                    >
                                        {" "}
                                        Select the lead source
                                    </div>
                                    <div
                                        className="dropdownParentSection"
                                        ref={ref1}
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            className="dropdown-planb"
                                            onClick={() =>
                                                setleadsoruce({
                                                    toggle: !leadsoruce?.toggle,
                                                    name: leadsoruce?.name,
                                                })
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {leadsoruce.name == ""
                                                    ? "Click To Select"
                                                    : leadsoruce.name}
                                            </div>
                                            {leadsoruce.toggle ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            )}
                                        </div>
                                        {leadsoruce.toggle && (
                                            <div className="dropdwonlist">
                                                {leadlistcuton?.map((item) => {
                                                    return (
                                                        <div
                                                            className="listitemName"
                                                            onClick={() => selectleadsoruce(item?.name)}
                                                        >
                                                            {item?.name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>

                                    {showrefbox && (
                                        <>
                                            <div
                                                className="titleseleted"
                                                style={{
                                                    fontWeight: "700",
                                                }}
                                            >
                                                {" "}
                                                Enter affiliate
                                            </div>
                                            <div className="dropdownParentSectioncutom">
                                                <input
                                                    value={mailchek}
                                                    placeholder="Email.."
                                                    className="input"
                                                    onChange={(e) => setmailchek(e.target.value)}
                                                />
                                                <label onClick={cmailcheck}>Check</label>
                                            </div>
                                        </>
                                    )}

                                    {answerref && (
                                        <>
                                            <div
                                                className="titleseleted"
                                                style={{
                                                    fontWeight: "700",
                                                }}
                                            >
                                                {" "}
                                                Enter affiliate
                                            </div>

                                            <div
                                                className="dropdownParentSection"
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                <div
                                                    className="dropdown-planb"
                                                    style={{
                                                        height: "60px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                width: "41px",
                                                                height: "41px",
                                                                borderRadius: "10rem",
                                                                marginRight: "10px",
                                                            }}
                                                            src={refmail?.dynamic?.[0]?.data?.profile_img}
                                                        />
                                                        <div
                                                            style={{
                                                                width: "15rem",

                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                            }}
                                                        >
                                                            {refmail?.hardCoded?.[0]?.data?.username}

                                                            <div>
                                                                {refmail?.hardCoded?.[0]?.data?.affiliate_id}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <svg
                                                        onClick={() => {
                                                            setshowrefbox(true);
                                                            setanswerref(false);
                                                        }}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="19"
                                                        height="22"
                                                        viewBox="0 0 19 22"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M17.4289 6.9958C17.2278 6.68143 16.8128 6.61855 16.511 6.83233L14.4738 8.36652C14.2223 8.5551 14.1469 8.90724 14.3103 9.18385C14.8259 10.0641 15.09 11.0072 15.09 11.9881C15.09 15.1067 12.5498 17.6469 9.43124 17.6469C6.31269 17.6469 3.7725 15.1067 3.7725 11.9881C3.7725 9.08329 5.97312 6.68143 8.80249 6.36705V8.21562C8.80249 8.59287 9.10429 8.83179 9.43124 8.84437C9.55695 8.84437 9.69536 8.8066 9.80849 8.71858L14.8385 4.94616C15.1781 4.69466 15.1781 4.19162 14.8385 3.94012L9.80849 0.125708C9.69536 0.0377669 9.55699 0 9.43124 0C9.10429 0 8.80249 0.264117 8.80249 0.62875V2.58211C3.89821 2.89644 0 6.9958 0 11.9881C0 17.1942 4.2252 21.4613 9.43124 21.4613C14.6373 21.4613 18.8625 17.1942 18.8625 11.9881C18.8625 10.2276 18.3594 8.5048 17.4289 6.9958Z"
                                                            fill="#C57E50"
                                                        />
                                                    </svg>
                                                </div>
                                                {leadsoruce.toggle && (
                                                    <div className="dropdwonlist">
                                                        {leadlistcuton?.map((item) => {
                                                            return (
                                                                <div
                                                                    className="listitemName"
                                                                    onClick={() => selectleadsoruce(item?.name)}
                                                                >
                                                                    {item?.name}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}

                                    {/*  */}
                                    <div className="linestring"></div>
                                    <div
                                        className="titleseleted"
                                        style={{
                                            fontWeight: "700",
                                        }}
                                    >
                                        {" "}
                                        Client details
                                    </div>

                                    <div className="dropdownParentSectioncutom">
                                        <input
                                            value={clientName}
                                            placeholder="Client name..."
                                            className="input"
                                            onChange={(e) => setclientName(e.target.value)}
                                        />
                                    </div>

                                    <div
                                        className="dropdownParentSectioncutom"
                                        style={{
                                            width: "100%",

                                            marginTop: "0.8rem",
                                        }}
                                    >
                                        <input
                                            value={clientphone}
                                            placeholder="Phone number..."
                                            className="input"
                                            onChange={(e) => setclientphone(e.target.value)}
                                        />
                                    </div>

                                    <div
                                        className="dropdownParentSectioncutom"
                                        style={{
                                            width: "100%",

                                            marginTop: "0.8rem",
                                        }}
                                    >
                                        <input
                                            value={emailclient}
                                            placeholder="Email..."
                                            className="input"
                                            onChange={(e) => setemailclient(e.target.value)}
                                        />
                                    </div>

                                    <div
                                        className="dropdownParentSection"
                                        ref={ref1}
                                        style={{
                                            width: "100%",

                                            marginTop: "0.8rem",
                                        }}
                                    >
                                        <div
                                            className="dropdown-planb"
                                            onClick={() =>
                                                setgender({
                                                    toggle: !gender?.toggle,
                                                    name: gender?.name,
                                                })
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {gender.name == "" ? "Select gender" : gender.name}
                                            </div>
                                            {gender.toggle ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            )}
                                        </div>
                                        {gender.toggle && (
                                            <div className="dropdwonlist">
                                                {person?.map((item) => {
                                                    return (
                                                        <div
                                                            className="listitemName"
                                                            onClick={() => selectgender(item?.name)}
                                                        >
                                                            {item?.name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>

                                    <div
                                        className="dropdownParentSection"
                                        style={{
                                            marginTop: "0.8rem",
                                            width: "100%",
                                        }}
                                    >
                                        <div className="dropdown-planb">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <input
                                                    type="text"
                                                    id="datepicker1"
                                                    ref={datepickerRef1}
                                                    className="dateinput"
                                                    placeholder="Select birthday"
                                                    value={brithday}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="dropdownParentSectioncutom"
                                        style={{
                                            width: "100%",

                                            marginTop: "0.8rem",
                                        }}
                                    >
                                        <input
                                            value={userNameclient}
                                            placeholder="Username..."
                                            className="input"
                                            onChange={(e) => setuserNameclient(e.target.value)}
                                        />
                                    </div>
                                    <div className="linestring"></div>
                                    <div
                                        className="titleseleted"
                                        style={{
                                            fontWeight: "700",
                                        }}
                                    >
                                        {" "}
                                        Client address
                                    </div>

                                    <div
                                        className="dropdownParentSection"
                                        ref={ref1}
                                        style={{
                                            width: "100%",

                                            // marginTop: "0.8rem"
                                        }}
                                    >
                                        <div
                                            className="dropdown-planb"
                                            onClick={() =>
                                                setcountry({
                                                    toggle: !country?.toggle,
                                                    name: country?.name,
                                                })
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {country.name == "" ? "Select country" : country.name}
                                            </div>
                                            {country.toggle ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            )}
                                        </div>
                                        {country.toggle && (
                                            <div
                                                className="dropdwonlist"
                                                style={{
                                                    height: "12rem",
                                                    overflow: "scroll",
                                                }}
                                            >
                                                {coundata?.map((item) => {
                                                    return (
                                                        <div
                                                            className="listitemName"
                                                            onClick={() => countryselete(item)}
                                                        >
                                                            {item?.name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>

                                    <div
                                        className="dropdownParentSectioncutom"
                                        style={{
                                            width: "100%",

                                            marginTop: "0.8rem",
                                        }}
                                    >
                                        <input
                                            value={state}
                                            placeholder="State..."
                                            className="input"
                                            onChange={(e) => setstate(e.target.value)}
                                        />
                                    </div>

                                    <div
                                        className="dropdownParentSectioncutom"
                                        style={{
                                            width: "100%",

                                            marginTop: "0.8rem",
                                        }}
                                    >
                                        <input
                                            value={city}
                                            placeholder="City..."
                                            className="input"
                                            onChange={(e) => setcity(e.target.value)}
                                        />
                                    </div>

                                    <div
                                        className="dropdownParentSectioncutom"
                                        style={{
                                            width: "100%",

                                            marginTop: "0.8rem",
                                        }}
                                    >
                                        <input
                                            value={address}
                                            placeholder="Address..."
                                            className="input"
                                            onChange={(e) => setaddress(e.target.value)}
                                        />
                                    </div>
                                    <div
                                        className="dropdownParentSectioncutom"
                                        style={{
                                            width: "100%",

                                            marginTop: "0.8rem",
                                        }}
                                    >
                                        <input
                                            value={pincode}
                                            placeholder="Pincode..."
                                            className="input"
                                            onChange={(e) => setpincode(e.target.value)}
                                        />
                                    </div>

                                    <div className="linestring"></div>
                                    <div
                                        className="titleseleted"
                                        style={{
                                            fontWeight: "700",
                                        }}
                                    >
                                        {" "}
                                        Appointment details
                                    </div>

                                    <div className="titleseleted">Select service</div>
                                    <div
                                        className="dropdownParentSection"
                                        ref={ref1}
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            className="dropdown-planb"
                                            onClick={() =>
                                                setservicestore({
                                                    toggle: !servicestore?.toggle,
                                                    name: servicestore?.name,
                                                })
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {servicestore.name == ""
                                                    ? "Click To Select"
                                                    : servicestore.name}
                                            </div>
                                            {servicestore.toggle ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            )}
                                        </div>

                                        {servicestore.toggle && (
                                            <div className="dropdwonlist">
                                                {servicedropClinet?.map((item) => {
                                                    return (
                                                        <div
                                                            className="listitemName"
                                                            onClick={() => seletservice(item)}
                                                        >
                                                            {item?.name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>

                                    <div className="titleseleted">Select location</div>
                                    <div
                                        className="dropdownParentSection"
                                        ref={ref1}
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            className="dropdown-planb"
                                            onClick={() =>
                                                setloctionService({
                                                    toggle: !loctionService?.toggle,
                                                    name: loctionService?.name,
                                                })
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {loctionService.name == ""
                                                    ? "Click To Select"
                                                    : loctionService.name}
                                            </div>
                                            {loctionService.toggle ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            )}
                                        </div>

                                        {loctionService.toggle && (
                                            <div className="dropdwonlist">
                                                {locationdrop?.map((item) => {
                                                    return (
                                                        <div
                                                            className="listitemName"
                                                            onClick={() => seletlocationfucn(item)}
                                                        >
                                                            {item?.locationDetails?.[0]?.name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>

                                    <div className="titleseleted">Select specialist</div>
                                    <div
                                        className="dropdownParentSection"
                                        ref={ref1}
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            className="dropdown-planb"
                                            onClick={() =>
                                                setspecService({
                                                    toggle: !specService?.toggle,
                                                    name: specService?.name,
                                                })
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {specService.name == ""
                                                    ? "Click To Select"
                                                    : specService.name}
                                            </div>
                                            {specService.toggle ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            )}
                                        </div>

                                        {specService.toggle && (
                                            <div className="dropdwonlist">
                                                {specDrown?.map((item) => {
                                                    return (
                                                        <div
                                                            className="listitemName"
                                                            onClick={() => seletspecfuncion(item)}
                                                        >
                                                            {item?.TeamDetails?.[0]?.PlayerDetails?.[0]?.name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>

                                    {/*  */}

                                    <div className="titleseleted"> Select the date</div>
                                    <div
                                        className="dropdownParentSection"
                                        // ref={ref1}
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            className="dropdown-planb"

                                        // onClick={() => setlead({
                                        //   toggle: !lead?.toggle,
                                        //   name: lead?.name
                                        // })}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <input
                                                    type="text"
                                                    id="datepicker"
                                                    ref={datepickerRef2}
                                                    className="dateinput"
                                                    placeholder="Click To Select"
                                                    value={appointdate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="titleseleted"> Select the time</div>
                                    <div
                                        className="dropdownParentSection"
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            className="dropdown-planb"
                                            onClick={() =>
                                                settimeshow({
                                                    toggle: !timeshow?.toggle,
                                                    name: timeshow?.name,
                                                })
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {timeshow.name == ""
                                                    ? "Click To Select"
                                                    : timeshow.name}
                                            </div>
                                            {timeshow.toggle ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="6"
                                                    height="5"
                                                    viewBox="0 0 6 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                        fill="#7D7D7D"
                                                    />
                                                </svg>
                                            )}
                                        </div>

                                        {timeshow.toggle && (
                                            <div
                                                className="dropdwonlist"
                                                style={{
                                                    height: "12rem",
                                                    overflow: " scroll",
                                                }}
                                            >
                                                {timpicker?.map((item) => {
                                                    return (
                                                        <div
                                                            className="listitemName"
                                                            onClick={() => selettimeshow(item)}
                                                        >
                                                            {item}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>

                                    <label
                                        className="submit"
                                        style={{
                                            marginTop: "2.5rem",
                                        }}
                                        onClick={submitnewdata}
                                    >
                                        {loading ? "Loading..." : "Submit"}
                                    </label>

                                    <label
                                        className="submit"
                                        style={{
                                            background: "linear-gradient(89.14deg, #F04E8C 0.79%, #F8BA4E 143.99%)",
                                        }}
                                        onClick={() => setarr("main")}
                                    >
                                        Go Back
                                    </label>
                                </div>
                            </div>
                        </div>
                    </>
                );
                break;
            case "main":
                return (
                    <>
                        <div className="scroll-bottom-div">
                            <div
                                className="titleseleted"
                                style={{
                                    fontWeight: "700",
                                }}
                            >
                                What type of client are you creating a booking for?
                            </div>

                            <div
                                className="labelbuttonbokin"
                                onClick={() => {
                                    callcler("newclinet");
                                    setstep("newclinet");
                                }}
                            >
                                New client
                            </div>

                            <div
                                className="labelbuttonbokin"
                                onClick={() => {
                                    callcler("existing");
                                    setstep("existing");
                                }}
                            >
                                Existing client
                            </div>
                        </div>
                    </>
                );

                break;


            case "theropyexisting":
                return (
                    <>
                        <div className="booking-dashboard">
                            <div
                                className="scroll-bottom-div"
                                style={{
                                    paddingTop: "4rem",
                                }}
                            >
                                <div
                                    className="titleseleted"
                                    style={{
                                        fontWeight: "700",
                                    }}
                                >
                                    {" "}
                                    Select client
                                </div>
                                <div
                                    className="dropdownParentSection"
                                    ref={ref1}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        className="dropdown-planb"
                                        onClick={() =>
                                            setexsitingclient({
                                                toggle: !exsitingclient?.toggle,
                                                name: exsitingclient?.name,
                                            })
                                        }
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {exsitingclient.name == ""
                                                ? "Click To Select"
                                                : exsitingclient.name}
                                        </div>
                                        {exsitingclient.toggle ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                    {exsitingclient.toggle && (
                                        <div className="dropdwonlist">
                                            {clientstore?.map((item) => {
                                                return (
                                                    <div
                                                        className="listitemName"
                                                        onClick={() => seletclientfunct(item)}
                                                    >
                                                        {item?.UserDetails?.[0]?.UserDetails?.[0]?.name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>


                                <div
                                    className="titleseleted"
                                    style={{
                                        fontWeight: "700",
                                    }}
                                >
                                    {" "}
                                    Select Therapy
                                </div>
                                <div
                                    className="dropdownParentSection"
                                    ref={ref1}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        className="dropdown-planb"
                                        onClick={() =>
                                            settexsitingclient({
                                                toggle: !texsitingclient?.toggle,
                                                name: texsitingclient?.name,
                                            })
                                        }
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {texsitingclient.name == ""
                                                ? "Click To Select"
                                                : texsitingclient.name}
                                        </div>
                                        {texsitingclient.toggle ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                    {texsitingclient.toggle && (
                                        <div className="dropdwonlist">
                                            {SelectTherpy?.map((item) => {
                                                return (
                                                    <div
                                                        className="listitemName"
                                                        onClick={() => theropyseletclientfunct(item)}
                                                    >
                                                        {item?.name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>

                                {/* <div
                                    className="titleseleted"
                                    style={{
                                        fontWeight: "700",
                                    }}
                                >
                                    Select service
                                </div> */}
                                {/* <div
                                    className="dropdownParentSection"
                                    ref={ref1}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        className="dropdown-planb"
                                        onClick={() =>
                                            setservicestore({
                                                toggle: !servicestore?.toggle,
                                                name: servicestore?.name,
                                            })
                                        }
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {servicestore.name == ""
                                                ? "Click To Select"
                                                : servicestore.name}
                                        </div>
                                        {servicestore.toggle ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        )}
                                    </div>

                                    {servicestore.toggle && (
                                        <div className="dropdwonlist">
                                            {servicedropClinet?.map((item) => {
                                                return (
                                                    <div
                                                        className="listitemName"
                                                        onClick={() => seletservice(item)}
                                                    >
                                                        {item?.name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div> */}

                                <div
                                    className="titleseleted"
                                    style={{
                                        fontWeight: "700",
                                    }}
                                >
                                    Select location
                                </div>
                                <div
                                    className="dropdownParentSection"
                                    ref={ref1}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        className="dropdown-planb"
                                        onClick={() =>
                                            setloctionService({
                                                toggle: !loctionService?.toggle,
                                                name: loctionService?.name,
                                            })
                                        }
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {loctionService.name == ""
                                                ? "Click To Select"
                                                : loctionService.name}
                                        </div>
                                        {loctionService.toggle ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        )}
                                    </div>

                                    {loctionService.toggle && (
                                        <div className="dropdwonlist">
                                            {Therpylocationdrop?.map((item) => {
                                                return (
                                                    <div
                                                        className="listitemName"
                                                        onClick={() => Therpyexsitingseletlocationfucn(item)}
                                                    >
                                                        {item?.name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>

                                <div
                                    className="titleseleted"
                                    style={{
                                        fontWeight: "700",
                                    }}
                                >
                                    Select specialist
                                </div>
                                <div
                                    className="dropdownParentSection"
                                    ref={ref1}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        className="dropdown-planb"
                                        onClick={() =>
                                            setspecService({
                                                toggle: !specService?.toggle,
                                                name: specService?.name,
                                            })
                                        }
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {specService.name == ""
                                                ? "Click To Select"
                                                : specService.name}
                                        </div>
                                        {specService.toggle ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        )}
                                    </div>

                                    {specService.toggle && (
                                        <div className="dropdwonlist">
                                            {TherpyspecDrown?.map((item) => {
                                                console.log(item, "ooooo");
                                                return (
                                                    <div
                                                        className="listitemName"
                                                        onClick={() => seletspecfuncion(item)}
                                                    >
                                                        {item?.TeamDetails?.[0]?.PlayerDetails?.[0]?.name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>

                                {/*  */}

                                <div
                                    className="titleseleted"
                                    style={{
                                        fontWeight: "700",
                                    }}
                                >
                                    {" "}
                                    Select the date
                                </div>
                                <div
                                    className="dropdownParentSection"
                                    // ref={ref1}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        className="dropdown-planb"

                                    // onClick={() => setlead({
                                    //   toggle: !lead?.toggle,
                                    //   name: lead?.name
                                    // })}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <input
                                                type="text"
                                                id="datepicker"
                                                ref={datepickerRef2}
                                                className="dateinput"
                                                placeholder="Click To Select"
                                                value={appointdate}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="titleseleted"
                                    style={{
                                        fontWeight: "700",
                                    }}
                                >
                                    {" "}
                                    Select the time
                                </div>
                                <div
                                    className="dropdownParentSection"
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        className="dropdown-planb"
                                        onClick={() =>
                                            settimeshow({
                                                toggle: !timeshow?.toggle,
                                                name: timeshow?.name,
                                            })
                                        }
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {timeshow.name == "" ? "Click To Select" : timeshow.name}
                                        </div>
                                        {timeshow.toggle ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        )}
                                    </div>

                                    {timeshow.toggle && (
                                        <div
                                            className="dropdwonlist"
                                            style={{
                                                height: "12rem",
                                                overflow: " scroll",
                                            }}
                                        >
                                            {timpicker?.map((item) => {
                                                return (
                                                    <div
                                                        className="listitemName"
                                                        onClick={() => selettimeshow(item)}
                                                    >
                                                        {item}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>

                                <label
                                    className="submit"
                                    style={{
                                        marginTop: "2.5rem",
                                    }}
                                    onClick={therpysubmitfuction}
                                >
                                    {loading ? "Loading..." : "Submit"}
                                </label>

                                <label
                                    className="submit"
                                    style={{
                                        background: "linear-gradient(89.14deg, #F04E8C 0.79%, #F8BA4E 143.99%)",
                                    }}
                                    onClick={() => setarr("main")}
                                >
                                    Go Back
                                </label>
                            </div>
                        </div>
                    </>
                );

                break;
            case "existing":
                return (
                    <>
                        <div className="booking-dashboard">
                            <div
                                className="scroll-bottom-div"
                                style={{
                                    paddingTop: "4rem",
                                }}
                            >
                                <div
                                    className="titleseleted"
                                    style={{
                                        fontWeight: "700",
                                    }}
                                >
                                    {" "}
                                    Select client
                                </div>
                                <div
                                    className="dropdownParentSection"
                                    ref={ref1}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        className="dropdown-planb"
                                        onClick={() =>
                                            setexsitingclient({
                                                toggle: !exsitingclient?.toggle,
                                                name: exsitingclient?.name,
                                            })
                                        }
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {exsitingclient.name == ""
                                                ? "Click To Select"
                                                : exsitingclient.name}
                                        </div>
                                        {exsitingclient.toggle ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                    {exsitingclient.toggle && (
                                        <div className="dropdwonlist">
                                            {clientstore?.map((item) => {
                                                return (
                                                    <div
                                                        className="listitemName"
                                                        onClick={() => seletclientfunct(item)}
                                                    >
                                                        {item?.UserDetails?.[0]?.UserDetails?.[0]?.name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>

                                <div
                                    className="titleseleted"
                                    style={{
                                        fontWeight: "700",
                                    }}
                                >
                                    Select service
                                </div>
                                <div
                                    className="dropdownParentSection"
                                    ref={ref1}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        className="dropdown-planb"
                                        onClick={() =>
                                            setservicestore({
                                                toggle: !servicestore?.toggle,
                                                name: servicestore?.name,
                                            })
                                        }
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {servicestore.name == ""
                                                ? "Click To Select"
                                                : servicestore.name}
                                        </div>
                                        {servicestore.toggle ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        )}
                                    </div>

                                    {servicestore.toggle && (
                                        <div className="dropdwonlist">
                                            {servicedropClinet?.map((item) => {
                                                return (
                                                    <div
                                                        className="listitemName"
                                                        onClick={() => seletservice(item)}
                                                    >
                                                        {item?.name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>

                                <div
                                    className="titleseleted"
                                    style={{
                                        fontWeight: "700",
                                    }}
                                >
                                    Select location
                                </div>
                                <div
                                    className="dropdownParentSection"
                                    ref={ref1}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        className="dropdown-planb"
                                        onClick={() =>
                                            setloctionService({
                                                toggle: !loctionService?.toggle,
                                                name: loctionService?.name,
                                            })
                                        }
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {loctionService.name == ""
                                                ? "Click To Select"
                                                : loctionService.name}
                                        </div>
                                        {loctionService.toggle ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        )}
                                    </div>

                                    {loctionService.toggle && (
                                        <div className="dropdwonlist">
                                            {locationdrop?.map((item) => {
                                                return (
                                                    <div
                                                        className="listitemName"
                                                        onClick={() => seletlocationfucn(item)}
                                                    >
                                                        {item?.locationDetails?.[0]?.name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>

                                <div
                                    className="titleseleted"
                                    style={{
                                        fontWeight: "700",
                                    }}
                                >
                                    Select specialist
                                </div>
                                <div
                                    className="dropdownParentSection"
                                    ref={ref1}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        className="dropdown-planb"
                                        onClick={() =>
                                            setspecService({
                                                toggle: !specService?.toggle,
                                                name: specService?.name,
                                            })
                                        }
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {specService.name == ""
                                                ? "Click To Select"
                                                : specService.name}
                                        </div>
                                        {specService.toggle ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        )}
                                    </div>

                                    {specService.toggle && (
                                        <div className="dropdwonlist">
                                            {specDrown?.map((item) => {
                                                console.log(item, "ooooo");
                                                return (
                                                    <div
                                                        className="listitemName"
                                                        onClick={() => seletspecfuncion(item)}
                                                    >
                                                        {item?.TeamDetails?.[0]?.PlayerDetails?.[0]?.name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>

                                {/*  */}

                                <div
                                    className="titleseleted"
                                    style={{
                                        fontWeight: "700",
                                    }}
                                >
                                    {" "}
                                    Select the date
                                </div>
                                <div
                                    className="dropdownParentSection"
                                    // ref={ref1}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        className="dropdown-planb"

                                    // onClick={() => setlead({
                                    //   toggle: !lead?.toggle,
                                    //   name: lead?.name
                                    // })}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <input
                                                type="text"
                                                id="datepicker"
                                                ref={datepickerRef2}
                                                className="dateinput"
                                                placeholder="Click To Select"
                                                value={appointdate}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="titleseleted"
                                    style={{
                                        fontWeight: "700",
                                    }}
                                >
                                    {" "}
                                    Select the time
                                </div>
                                <div
                                    className="dropdownParentSection"
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        className="dropdown-planb"
                                        onClick={() =>
                                            settimeshow({
                                                toggle: !timeshow?.toggle,
                                                name: timeshow?.name,
                                            })
                                        }
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {timeshow.name == "" ? "Click To Select" : timeshow.name}
                                        </div>
                                        {timeshow.toggle ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="6"
                                                height="5"
                                                viewBox="0 0 6 5"
                                                fill="none"
                                            >
                                                <path
                                                    d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                    fill="#7D7D7D"
                                                />
                                            </svg>
                                        )}
                                    </div>

                                    {timeshow.toggle && (
                                        <div
                                            className="dropdwonlist"
                                            style={{
                                                height: "12rem",
                                                overflow: " scroll",
                                            }}
                                        >
                                            {timpicker?.map((item) => {
                                                return (
                                                    <div
                                                        className="listitemName"
                                                        onClick={() => selettimeshow(item)}
                                                    >
                                                        {item}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>

                                <label
                                    className="submit"
                                    style={{
                                        marginTop: "2.5rem",
                                    }}
                                    onClick={submitfuction}
                                >
                                    Submit
                                </label>

                                <label
                                    className="submit"
                                    style={{
                                        background: "linear-gradient(89.14deg, #F04E8C 0.79%, #F8BA4E 143.99%)",
                                    }}
                                    onClick={() => setarr("main")}
                                >
                                    Go Back
                                </label>
                            </div>
                        </div>
                    </>
                );

                break;
            default:
                break;
        }
    };

    const [arr, setarr] = useState("main");

    const adddata = () => {
        switch (arr) {
            case "submain":
                return <>{createnewfunction()}</>;
            case "main":
                return (
                    <>
                        <div
                            className="drowpside-right"
                            style={{
                                height: "100%",
                            }}
                        >
                            <div
                                className={classNames.eachFilterItem}
                                style={{
                                    fontWeight: "700",
                                    pointerEvents: "none",
                                    paddingLeft: "0",
                                    marginTop: "64px",

                                    marginBottom: "2rem",
                                }}
                            >
                                Individual Booking
                            </div>

                            <div
                                className="listofitembooking"
                                onClick={() => {
                                    empydata()
                                    setarr("submain");
                                    callcler("newclinet");
                                    setstep("newclinet");
                                }}
                            >
                                Consultation Session - New Client
                            </div>

                            <div
                                className="listofitembooking"
                                onClick={() => {
                                    empytexitingdat()
                                    setarr("submain");
                                    callcler("existing");
                                    setstep("existing");

                                }}
                            >
                                Consultation Session - Existing Client
                            </div>

                            <div className="listofitembooking"

                                onClick={() => {
                                    empydata()
                                    setarr("submain");
                                    callcler("therapyNew");
                                    setstep("therapyNew");
                                }}
                            >
                                Therapy Session - New Client
                            </div>
                            <div className="listofitembooking"

                                onClick={() => {
                                    empytexitingdat()
                                    setarr("submain");
                                    callcler("theropyexisting");
                                    setstep("theropyexisting");

                                }}

                            >
                                Therapy Session - Existing Client
                            </div>

                            <div
                                className={classNames.eachFilterItem}
                                style={{
                                    fontWeight: "700",
                                    pointerEvents: "none",
                                    paddingLeft: "0",
                                    marginTop: "2rem",

                                    marginBottom: "2rem",
                                }}
                            >
                                Package Purchase
                            </div>
                            <div
                                className="listofitembooking"
                                style={{
                                    opacity: "0.5",
                                }}
                            >
                                Package - New Client
                            </div>
                            <div
                                className="listofitembooking"
                                style={{
                                    opacity: "0.5",
                                }}
                            >
                                Package - Existing Client
                            </div>

                            <div
                                className={classNames.eachFilterItem}
                                style={{
                                    fontWeight: "700",
                                    pointerEvents: "none",
                                    paddingLeft: "0",
                                    marginTop: "2rem",

                                    marginBottom: "2rem",
                                }}
                            >
                                Booking Inside Of A Package
                            </div>
                            <div
                                className="listofitembooking"
                                style={{
                                    opacity: "0.5",
                                }}
                            >
                                Consultation Session
                            </div>
                            <div
                                className="listofitembooking"
                                style={{
                                    opacity: "0.5",
                                }}
                            >
                                Therapy Session
                            </div>
                        </div>
                    </>
                );
                break;

            default:
                break;
        }
    };

    return (
        <>
            <ToastContainer />

            <div className="global-dashboard">
                <div className="label-box-header">Dashboard</div>
                <div className="top-section-golbal">
                    <div className="left-side">
                        <div className="label-box">
                            <svg
                                style={{
                                    marginRight: "10px",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                            >
                                <path
                                    d="M12.8445 8.07626L10.9486 6.58668C10.8262 6.4908 10.6594 6.47293 10.5202 6.54118C10.3804 6.60888 10.2916 6.7508 10.2916 6.90626V7.90292C8.75704 8.16184 7.58325 9.49651 7.58325 11.1042V12.4583C7.58325 12.7573 7.82592 13 8.12492 13C8.42392 13 8.66658 12.7573 8.66658 12.4583V11.1042C8.66658 10.0978 9.35992 9.25655 10.2916 9.01442V9.88542C10.2916 10.0409 10.3804 10.1828 10.5202 10.2505C10.5765 10.2781 10.6372 10.2917 10.6978 10.2917C10.7872 10.2917 10.876 10.2624 10.9486 10.205L12.8445 8.71542C12.943 8.63851 12.9999 8.52042 12.9999 8.39584C12.9999 8.27126 12.943 8.15317 12.8445 8.07626Z"
                                    fill="#5A5656"
                                />
                                <path
                                    d="M9.74999 0H1.625C0.731249 0 0 0.731249 0 1.625V8.66666C0 9.5604 0.731249 10.2917 1.625 10.2917H6.58124C6.65166 9.91249 6.77082 9.54957 6.93874 9.20832H1.625C1.32708 9.20832 1.08333 8.96457 1.08333 8.66666V2.70833H10.2917V5.47624C10.6492 5.37333 11.0392 5.41124 11.375 5.57916V1.625C11.375 0.731249 10.6437 0 9.74999 0V0Z"
                                    fill="#5A5656"
                                />
                            </svg>
                            Export
                        </div>

                        <>
                            <div className="label-box">
                                <svg
                                    style={{
                                        marginRight: "10px",
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="13"
                                    height="13"
                                    viewBox="0 0 13 13"
                                    fill="none"
                                >
                                    <path
                                        d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z"
                                        fill="#5A5656"
                                    />
                                </svg>
                                Add Filter
                            </div>
                        </>
                    </div>

                    <div className="right-side">
                        <div className="shortBy">Sort By</div>
                        <div className="dropdownlist">
                            Newest
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7"
                                height="6"
                                viewBox="0 0 7 6"
                                fill="none"
                            >
                                <path
                                    d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                                    fill="#5A5656"
                                />
                            </svg>
                        </div>
                        <div
                            className="addLabel"
                            onClick={() => {
                                setarr("main");
                                setCheckBoxStage("add");
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                            >
                                <path
                                    d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z"
                                    fill="white"
                                />
                            </svg>
                            Add New
                        </div>
                        <Drawer
                            size={450}
                            // duration={500}
                            open={checkBoxStage}
                            onClose={() => setCheckBoxStage("")}
                            direction="right"
                            className="bla bla bla"
                            overlayColor="#FFF"
                            overlayOpacity={0}
                        >
                            <>{adddata()}</>
                        </Drawer>
                    </div>
                </div>

                <div className="handle-table">
                    <div className="g-table-sec">
                        <div className="table-section">
                            <table style={{ minWidth: "100%" }}>
                                <thead>
                                    <tr
                                        style={{
                                            gridTemplateColumns: "min-content repeat(8, 1fr)",
                                        }}
                                    >
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Patient</th>
                                        <th>Specialist</th>

                                        <th>Location</th>
                                        <th>Type</th>
                                        <th>Service</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {alldata
                                        ?.filter((qitem) => qitem?._id.includes(globalSearchQuery))
                                        ?.map((row, index) => {
                                            let resultstart = row?.dateOfBooking?.split("/");
                                            return (
                                                <tr
                                                    key={row?.brokerage_name + index}
                                                    style={{
                                                        gridTemplateColumns: "min-content repeat(8, 1fr)",
                                                        pointerEvents: sidebarOpen ? "none" : "",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <td>
                                                        <div className="title">
                                                            {monthNames[resultstart[0]?.replace(/^0+/, "")]}{" "}
                                                            {toCardinal(resultstart[1])} {resultstart[2]}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="title">{row?.timeOfBooking}</div>
                                                    </td>

                                                    <td>
                                                        <div className="title">
                                                            {row?.clientdetails?.[0]?.name}
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className="title">
                                                            {
                                                                row?.specialistDetails?.[0]?.PlayerDetails?.[0]
                                                                    ?.name
                                                            }
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className="title">
                                                            {row?.locationDetails?.[0]?.name}
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className="title">
                                                            {
                                                                row?.theraphyDetails?.length > 0 &&
                                                                "Therapy"
                                                            }
                                                            {
                                                                row?.servicedetails?.length > 0 &&
                                                                "Consultation"
                                                            }

                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className="title">

                                                            {
                                                                row?.theraphyDetails?.length > 0 &&
                                                                row?.theraphyDetails?.[0]?.name
                                                            }
                                                            {
                                                                row?.servicedetails?.length > 0 &&
                                                                row?.servicedetails?.[0]?.name
                                                            }

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div

                                                            style={{
                                                                color: "white",
                                                                fontFamily: "Montserrat",
                                                                fontSize: "12px",
                                                                fontWeight: "700",
                                                                lineHeight: "30px",
                                                                textAlign: "center",
                                                                height: "39px",
                                                                borderRadius: "5px",
                                                                background: row?.bookingStatus?.toLowerCase() == "booked" ? "#34A853" :
                                                                    row?.bookingStatus?.toLowerCase() == "canceled" ? "#FF0000" :
                                                                        row?.bookingStatus?.toLowerCase() == "arrived" ? "#C57E50" :
                                                                            row?.bookingStatus?.toLowerCase() == "pre-assessed" ? "#0052B4" :
                                                                                row?.bookingStatus?.toLowerCase() == "consulting" ? "#FBBC05" : "#000000",
                                                                padding: "0 1rem",
                                                                alignItems: "center",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                textTransform: "capitalize",


                                                            }}
                                                        >
                                                            {row?.bookingStatus}
                                                        </div>
                                                    </td>

                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Organization;
