import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./Catories.module.scss";
import "../../assets/Scsss/Global.scss";
// import './catoriesSummarypage.scss'
//assets
import { ReactComponent as FilterIcon } from "../../assets/images/filter.svg";
import Drawer from "react-modern-drawer";

import {
  CopyBtn,
  CustomCheckbox,
  EachCustomDropdown,
  EachCustomImageUpload,
  EachCustomImageUploadcustom,
  EachCustomSelectMultiple,
  EachCustomSelectMultiples,
  EachInputCustom,
  EachInputsepfic,
  EachCustomTextarea,
  EachCustomSelectMultiplelist,
  OwnEachCustomDropdown,
  EachInput,
} from "../../components/Custom";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";
import { ReactComponent as SortArrows } from "../../assets/images/icons/sort.svg";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../context/Globalcontext";
import { useNetworkRequest } from "../../hooks/networkRequest";
import { deleteData, getData } from "../../assets/functions/apiCalls";
import axios from "axios";
import { toastify } from "../../assets/functions/toastify";
import { filterDetails } from "../../assets/functions";
import { APP_CODE } from "../../assets/data/constant";
import Editingpage from "../EditingPage/EditingPage";
const Organization = () => {
  const {
    email,
    actionBtns,
    setActionBtns,
    globalSearchQuery,
    dropDowndownvalue,
    editingToggle,
    seteditingToggle,
  } = useContext(GlobalContext);
  const [checkBoxStage, setCheckBoxStage] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [catoriesSummary, setcatoriesSummary] = useState([]);
  const [kichenItem, setkichenItem] = useState({
    temperature: "",
    name: "",
    description: "",
    website: "",
    coverPhoto: "",
    food: [],
  });
  console.log("32342342fdsfvfdv", kichenItem);
  const [isSpecialist, setIsSpecialist] = useState(null);
  useEffect(() => {
    tablefucntion();
  }, [dropDowndownvalue, isSpecialist]);
  const [locationdata, setlocationdata] = useState({});
  useEffect(() => {
    locationdropdown();
  }, []);
  const [loactionget, setloactionget] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://api.employment.group/api/getspecialistdetail?email=${email}`
      )
      .then(({ data }) => {
        if (data.success) {
          setIsSpecialist(data?.data[0]?.specialistDetails[0]);
        } else {
          setIsSpecialist(false);
        }
      });
  }, []);

  const tablefucntion = () => {
    if (isSpecialist === false) {
      axios

        .get(
          `https://api.employment.group/api/gettheraphysessions?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
        )
        .then((res) => {
          //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
          setcatoriesSummary(res?.data?.data);
        });
    } else if (isSpecialist !== null && isSpecialist !== false) {
      axios
        // https://api.employment.group/api/gettheraphysessions?specialistid=4cs38bc8ltbb5v0w
        .get(
          `https://api.employment.group/api/gettheraphysessions?specialistid=${isSpecialist?.specialistid}`
          //   `https://api.employment.group/api/gettheraphysessions?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
        )
        .then((res) => {
          //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
          setcatoriesSummary(res?.data?.data);
        });
    }
  };
  const locationdropdown = () => {
    axios
      // .get(`https://api.employment.group/api/getwidget`)
      .get(
        `https://api.employment.group/api/getlocation?teamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
      )
      .then((res) => {
        if (res?.data?.success) {
          setloactionget(res?.data?.data);
        } else {
          setloactionget([]);
        }
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
      });
  };

  const [player, setplayer] = useState([]);
  function toCardinal(num) {
    var ones = num % 10;
    var tens = num % 100;

    if (tens < 11 || tens > 13) {
      switch (ones) {
        case 1:
          return num + "st";
        case 2:
          return num + "nd";
        case 3:
          return num + "rd";
      }
    }

    return num + "th";
  }
  function ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }
  const monthNames = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  console.log("777777", kichenItem);
  const [active, setactive] = useState("Pre Assessment Form");

  const [page, setpage] = useState("main");
  const [getsesion, setgetsesion] = useState({});
  // useEffect(() => {
  //     setpage("second")
  // }, [])

  // const getclientid = (e) => {

  //     axios
  //         .get(`https://api.employment.group/api/getconsultcatoriesSummarys?consultationcatoriesSummaryid=${e}`)
  //         .then((res) => {
  //             //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))

  //             setgetsesion(res?.data?.data?.[0] || {})
  //             setpage("second")

  //         });
  // }

  const [formName, setFormName] = useState("");
  const [questions, setQuestions] = useState([
    {
      name: "",
      answerType: "",
      isMandatory: null,
    },
  ]);
  const [isOpen, setIsOpen] = useState(null);
  const [formErr, setFormErr] = useState(null);
  const [allSpecialist, setAllSpecialist] = useState([]);
  const [selectedSpecialist, setSelectedSpecialist] = useState(null);





  const handleSubmit = () => {
    axios
      .post(`https://api.employment.group/api/addkitchenitem`, {
        teamusername: JSON.parse(localStorage?.getItem("orginzationdetail"))
          ?.TeamUsername,
        name: kichenItem?.name,
        image: kichenItem?.coverPhoto,


        foodType: kichenItem?.food?.[0],
        quantityform: kichenItem?.quantityform,
        quantitytype: kichenItem?.quantitytype,
        quantity: kichenItem?.quantity,
        temperature: kichenItem?.temperature?.[0],
        // "displayColour": kichenItem?.displayColour,
        servedin: kichenItem?.servedin?.[0],
        locationid: kichenItem?.location,
      })

      .then(({ data }) => {
        if (data.status) {
          setCheckBoxStage("");
          // setTimeout(() => {
          //     window.location.reload();
          // }, 1000);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          setFormErr(null);
        } else {
          setFormErr(data.message);
        }
      });
  };

  const localclikc = () => {
    let addlocal = localStorage?.getItem("drawershow");

    localStorage?.setItem("drawershow", Number(addlocal + 1));
  };
  let loc = window.location.pathname;
  let finallo = loc?.split("/");
  console.log("456546456", finallo[2]);

  const [dropdowntoggle, setdropdowntoggle] = useState(false)
  const [storeid, setstoreid] = useState("")

  const handleSubmiupdatet = (id, name) => {

    setdropdowntoggle(false)
    axios
      .put(`https://api.employment.group/api/updatetheraphysession/${id}`, {
        "status": name
      })

      .then(({ data }) => {
        if (data.success) {
          setCheckBoxStage("");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          setFormErr(null);
        } else {
          setFormErr(data.message);
        }
      });
  };
  useEffect(() => {
    seteditingToggle(false)
  }, [])
  return (
    <>
      {editingToggle ? (
        <Editingpage />
      ) : (
        <div className="global-dashboard">
          <div className="label-box-header" onClick={localclikc}>
            Therapy Sessions
          </div>
          <div className="top-section-golbal">
            <div className="left-side">
              <div className="label-box">
                <svg
                  style={{
                    marginRight: "10px",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <path
                    d="M12.8445 8.07626L10.9486 6.58668C10.8262 6.4908 10.6594 6.47293 10.5202 6.54118C10.3804 6.60888 10.2916 6.7508 10.2916 6.90626V7.90292C8.75704 8.16184 7.58325 9.49651 7.58325 11.1042V12.4583C7.58325 12.7573 7.82592 13 8.12492 13C8.42392 13 8.66658 12.7573 8.66658 12.4583V11.1042C8.66658 10.0978 9.35992 9.25655 10.2916 9.01442V9.88542C10.2916 10.0409 10.3804 10.1828 10.5202 10.2505C10.5765 10.2781 10.6372 10.2917 10.6978 10.2917C10.7872 10.2917 10.876 10.2624 10.9486 10.205L12.8445 8.71542C12.943 8.63851 12.9999 8.52042 12.9999 8.39584C12.9999 8.27126 12.943 8.15317 12.8445 8.07626Z"
                    fill="#5A5656"
                  />
                  <path
                    d="M9.74999 0H1.625C0.731249 0 0 0.731249 0 1.625V8.66666C0 9.5604 0.731249 10.2917 1.625 10.2917H6.58124C6.65166 9.91249 6.77082 9.54957 6.93874 9.20832H1.625C1.32708 9.20832 1.08333 8.96457 1.08333 8.66666V2.70833H10.2917V5.47624C10.6492 5.37333 11.0392 5.41124 11.375 5.57916V1.625C11.375 0.731249 10.6437 0 9.74999 0V0Z"
                    fill="#5A5656"
                  />
                </svg>
                Export
              </div>

              <>
                <div className="label-box">
                  <svg
                    style={{
                      marginRight: "10px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                  >
                    <path
                      d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z"
                      fill="#5A5656"
                    />
                  </svg>
                  Add Filter
                </div>
              </>
            </div>

            <div className="right-side">
              <div className="shortBy">Sort By</div>
              <div className="dropdownlist">
                Newest
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="6"
                  viewBox="0 0 7 6"
                  fill="none"
                >
                  <path
                    d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                    fill="#5A5656"
                  />
                </svg>
              </div>
              <div
                className="addLabel"
                //onClick={() => setCheckBoxStage("add")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <path
                    d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z"
                    fill="white"
                  />
                </svg>
                Add New
              </div>
              <Drawer
                size={450}
                // duration={500}
                open={checkBoxStage}
                onClose={() => setCheckBoxStage("")}
                direction="right"
                className="bla bla bla"
                overlayColor="#FFF"
                overlayOpacity={0}
              >
                {checkBoxStage == "add" && (
                  <div className="drowpside-right">
                    <div
                      style={{
                        position: "absolute",
                        right: "1rem",
                      }}
                      className={classNames.closeBtn}
                      onClick={() => {
                        setCheckBoxStage("");
                      }}
                    >
                      X
                    </div>
                    <div
                      className={classNames.eachFilterItem}
                      style={{
                        fontWeight: "500",
                        pointerEvents: "none",
                        paddingLeft: "0",
                      }}
                    >
                      Add kitchen item
                    </div>
                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <EachInputsepfic
                        title="What is the name of the item?"
                        name="name"
                        placeholder="Name..."
                        stateValue={kichenItem}
                        setState={setkichenItem}
                        topTitle="true"
                      />
                    </div>

                    <div
                      style={{
                        fontSize: "0.9rem",
                        marginBottom: "1rem",
                      }}
                    >
                      Upload photo of the item
                    </div>
                    <div
                      style={{
                        width: "200px",
                      }}
                    >
                      <EachCustomImageUpload
                        title="Upload employee profile photo"
                        name="coverPhoto"
                        placeholder="Upload"
                        stateValue={kichenItem}
                        setState={setkichenItem}
                      //  topTitle="true"
                      />
                    </div>

                    <div
                      style={{
                        marginTop: "2rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <EachCustomSelectMultiples
                        title="What is the food type?"
                        name="food"
                        stateValue={kichenItem}
                        setState={setkichenItem}
                        topTitle="true"
                        mapItems={["Veg", "Non-Veg"]}
                      />
                    </div>

                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <EachInput
                        title="What is the quantity form?"
                        name="quantityform"
                        placeholder="Ex Plate..."
                        stateValue={kichenItem}
                        setState={setkichenItem}
                        topTitle="true"
                      />
                    </div>

                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <EachInput
                        title="What is the quantity name?"
                        name="quantitytype"
                        placeholder="Ex Grams..."
                        stateValue={kichenItem}
                        setState={setkichenItem}
                        topTitle="true"
                      />
                    </div>

                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <EachInput
                        title="What is the quantity?"
                        name="quantity"
                        placeholder="0.00"
                        stateValue={kichenItem}
                        setState={setkichenItem}
                        topTitle="true"
                      />
                    </div>

                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      {/* <EachInput
                                                    title="What is the temperature?"
                                                    name="temperature"
                                                    placeholder="Ex. Hot"
                                                    stateValue={kichenItem}
                                                    setState={setkichenItem}
                                                    topTitle="true"
                                                /> */}
                      <EachCustomSelectMultiples
                        title="What is the temperature?"
                        name="temperature"
                        stateValue={kichenItem}
                        setState={setkichenItem}
                        topTitle="true"
                        mapItems={["Hot", "Cold"]}
                      />
                    </div>

                    {/* <div
                                                style={{

                                                    marginBottom: "1rem"
                                                }}
                                            >
                                                <EachInput
                                                    title="What is the display colour?"
                                                    name="displayColour"
                                                    placeholder="Ex. Orange"
                                                    stateValue={kichenItem}
                                                    setState={setkichenItem}
                                                    topTitle="true"
                                                />
                                            </div> */}

                    <div
                      style={{
                        marginBottom: "2rem",
                      }}
                    >
                      <EachCustomSelectMultiples
                        title="Which meal is it served?"
                        name="servedin"
                        stateValue={kichenItem}
                        setState={setkichenItem}
                        topTitle="true"
                        mapItems={["Breakfast", "Lunch", "Dinner"]}
                      />
                    </div>

                    <div>
                      <EachCustomDropdown
                        dropdown={loactionget}
                        name="location"
                        title="At which location is this kitchen item served?"
                        stateValue={kichenItem}
                        setState={setkichenItem}
                        topTitle="true"
                      />
                    </div>

                    <button
                      className={classNames.submitBtn}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                    {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>}
                  </div>
                )}
              </Drawer>
            </div>
          </div>

          <div className="handle-table">
            <div className="g-table-sec">
              <div className="table-section">
                <table style={{ minWidth: "100%" }}>
                  <thead>
                    <tr
                      style={{
                        gridTemplateColumns: "min-content repeat(7, 1fr)",
                      }}
                    >
                      <th>Date</th>
                      <th>Location</th>
                      <th>Patient</th>
                      <th>Specialist</th>

                      <th>Service</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {catoriesSummary
                      ?.filter((qitem) =>
                        qitem?._id.includes(globalSearchQuery)
                      )
                      ?.map((row, index) => {
                        let resultstart =
                          row?.bookingDetails?.[0]?.dateOfBooking?.split("/");
                        return (
                          <tr
                            key={row?.brokerage_name + index}
                            style={{
                              gridTemplateColumns: "min-content repeat(7, 1fr)",
                              pointerEvents: sidebarOpen ? "none" : "",
                              cursor: "pointer",
                            }}
                          >
                            <td>
                              <div className="title">
                                {
                                  monthNames[
                                  resultstart?.[0]?.replace(/^0+/, "")
                                  ]
                                }{" "}
                                {toCardinal(resultstart?.[1])}{" "}
                                {resultstart?.[2]}
                              </div>
                            </td>
                            <td>
                              <div className="title">
                                {
                                  row?.bookingDetails?.[0]?.locationDetails?.[0]
                                    ?.name
                                }
                              </div>
                            </td>

                            <td>
                              <div className="title">
                                {row?.clientdetails?.[0]?.name}
                              </div>
                            </td>

                            <td>
                              <div className="title">
                                {
                                  row?.specialistDetails?.[0]
                                    ?.PlayerDetails?.[0]?.name
                                }
                              </div>
                            </td>

                            <td>
                              <div className="title">
                                {
                                  row?.bookingDetails?.[0]?.theraphyDetails?.[0]
                                    ?.name
                                }
                              </div>
                            </td>

                            <td>
                              <div
                                style={{
                                  width: "113px",
                                  height: "39px",
                                  background:
                                    row?.status == "open"
                                      ? "linear-gradient(0deg, #F04E8C  0%, #F79540 100%)"
                                      : row?.status == "close" && "linear-gradient(89.14deg, #F04E8C 0.79%, #F8BA4E 143.99%)",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  padding: "0 1rem",
                                  borderRadius: "5px",
                                  color: "#fff", fontWeight: "700",
                                }}
                                onClick={() => {
                                  setstoreid(row?._id)
                                  setdropdowntoggle(!dropdowntoggle)
                                }
                                }
                              >
                                {row?.status == "open" ? "Open" : row?.status == "close" && "Closed"}
                                {
                                  dropdowntoggle && row?._id == storeid ?
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg" width="7" height="6" viewBox="0 0 7 6" fill="none">
                                      <path d="M6.87533 4.8683L3.98854 0.304976C3.76648 -0.101659 3.27793 -0.101659 3.01146 0.304976L0.124669 4.8683C-0.186232 5.36528 0.124669 5.99785 0.613191 5.99785H6.38681C6.87533 6.04303 7.18623 5.36532 6.87533 4.8683Z" fill="white" />
                                    </svg> :
                                    <svg
                                      style={{
                                        transform: "rotate(60deg)"
                                      }}
                                      xmlns="http://www.w3.org/2000/svg" width="7" height="6" viewBox="0 0 7 6" fill="none">
                                      <path d="M6.87533 4.8683L3.98854 0.304976C3.76648 -0.101659 3.27793 -0.101659 3.01146 0.304976L0.124669 4.8683C-0.186232 5.36528 0.124669 5.99785 0.613191 5.99785H6.38681C6.87533 6.04303 7.18623 5.36532 6.87533 4.8683Z" fill="white" />
                                    </svg>
                                }
                              </div>
                              {
                                dropdowntoggle && row?._id == storeid &&

                                <div
                                  style={{
                                    width: "113px",
                                    fontWeight: "700",
                                    height: "39px",
                                    background: "#FFF",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderRadius: "5px",
                                    padding: "0 1rem",
                                    color: "#5A5656",
                                    boxShadow: "0px 4px 4px 0px hsla(0, 0%, 0%, 0.25)",
                                    textTransform: "capitalize",
                                    fontWeight: "700"

                                  }}
                                  onClick={() => handleSubmiupdatet(row?._id, row?.status == "open" ? "close" : row?.status == "close" && "open")}
                                >
                                  {row?.status == "open" ? "close" : row?.status == "close" && "open"}
                                </div>
                              }
                            </td>


                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Organization;
